interface CoerceFunction<Value = string> {
  (value: string): Value
}

interface QueryParams<Value = any> { // eslint-disable-line @typescript-eslint/no-explicit-any
  [key: string]: Value
}

export function paramsToQueryString(params: QueryParams = {}): string {
  const query = Object.entries(params).map(([key, value]) => `${key}=${value}`).join('&');
  return query ? `?${query}` : '';
}

export function queryStringToParams<Value = string>(query = '', coerce?: CoerceFunction<Value>): QueryParams<Value> {
  if (!query) {
    return {};
  }
  if (query[0] === '?') {
    query = query.slice(1);
  }
  return query.split('&').reduce((params, keyval) => {
    let [key, value = 'true'] = keyval.split('=');
    // decode each side after split because either could contain =
    key = decodeURIComponent(key);
    value = decodeURIComponent(value);
    return { ...params, [key]: coerce ? coerce(value) : value };
  }, {});
}

export function getUrlQueryParams(): { [key: string]: string } {
  return queryStringToParams(window.location.hash.split('?')[1]);
}
